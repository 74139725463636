import api from "@/utils/fetch";

/**
 * 积分回收
 * @param params
 * @param {string} params.currency 币种
 * @param {number} params.points 金额
 * @param {string} params.remark 回收备注
 * @param {string[]} params.userIds 用户Id集合
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function recoveryPoints(params) {
  return api.post("/company/points/recovery", params);
}

/**
 * 资源兑换
 * @param params
 * @param {string} params.currency 币种
 * @param {number} params.points 金额
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function exchangePoints(params) {
  return api.post("/company/points/exchange", params)
}

/**
 *
 * 获取企业币种兑换比例列表-分页
 *
 * @param params
 * @param {string} params.companyId 企业id
 * @param {string} params.fromCurrency 来源币种
 * @param {string} params.toCurrency 转换币种
 * @param {Number} params.pageNum 页数
 * @param {Number} params.pageSize 每页大小
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function exchangePointsRate(params) {
  return api.post("/company/points/rate/page", params)
}

/**
 *
 * 查询企业账单列表-分页
 *
 * @param params
 * @param {string} params.companyId 企业id
 * @param {string} params.formUserId 来源用户Id
 * @param {string} params.otherId 其他id
 * @param {string} params.purpose 用途
 * @param {string} params.userId 用户id
 * @param {Number} params.pageNum 页数
 * @param {Number} params.pageSize 每页大小
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function getBillPage(params) {
  return api.post("/company/points/bill/page", params)
}

/**
 * 获取兑换币种额度
 * @param params
 * @param {string} params.companyId 企业id
 * @param {string} params.fromCurrency 来源币种
 * @param {number} params.fromPoint 	来源币种额度
 * @param {string} params.toCurrency 	转换币种
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function ratePoints(params) {
  return api.post("/company/points/rate/points", params);
}

/**
 * 查询账单列表-分页
 * @param params
 * @param {string=} params.companyId
 * @param {string=} params.currency
 * @param {string=} params.endTime
 * @param {number} params.pageNum
 * @param {number} params.pageSize
 * @param {string=} params.purpose
 * @param {string=} params.startTime
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function pointsRecords(params) {
  return api.post("/company/points/record/page", params);
}
