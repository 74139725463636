<template>
  <div class="resource-list">
    <div class="resource-list_head">
      <a-button type="primary" @click="showModal">{{
        $t("resource.list.button")
      }}</a-button>
    </div>
    <div class="resource-list_content">
      <div
        class="content-card"
        v-for="i in balanceList"
        :key="i.currency"
        v-show="!['survey_coin'].includes(i.currency)"
      >
        <div
          class="content-card_left"
          :style="{ backgroundImage: `url(${i.icon || ''})` }"
        ></div>
        <div class="content-card_right">
          <div class="content-num color-primary">{{ i.pointsShown }}</div>
          <div class="content-flex">
            <span class="content-label">{{ i.currencyName }}</span>
            <a-popover trigger="hover" placement="bottom">
              <div
                slot="content"
                v-html="
                  $t(`resource.list.tips.${i.tip}`, {
                    currency: i.currencyName
                  })
                "
              />
              <span class="icon-tip" />
            </a-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="resource-list_table">
      <ECTable
        :title="$t('resource.list.record')"
        :columns="columns"
        :data-source="billList"
        showTitle
        :loading="loading"
        :pagination="{
          total,
          current: pageNum,
          pageSize
        }"
        @change="onTableChange"
        :scroll="{ x: true }"
      >
        <template slot="tableHeaderRight">
          <span class="margin">{{ $t("resource.list.time") }}</span>
          <a-range-picker
            format="YYYY-MM-DD"
            v-model="dateRange"
            @change="onDateRangeChange"
          />
        </template>
      </ECTable>
    </div>
    <template v-if="isShowModal">
      <resourceModal :visible.sync="isShowModal" @ok="onModalConfirm" />
    </template>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import Vue from "vue";
import resourceModal from "./Modal.vue";
import { exchangePoints, pointsRecords } from "@/pages/resource/api";
import { DateFormatFull, FETCH_CODE } from "@/config";
import { mapGetters, mapState } from "vuex";
import { showMsg } from "@/utils";
import { listPointsShowFormatter, pointsSaveFormatter } from "@/utils/tools";
import moment from "moment";

const columns = [
  {
    title: Vue.prototype.$t("resource.list.cols.time"),
    dataIndex: "createTime",
    width: 160,
    align: "center",
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("resource.list.cols.purpose"),
    dataIndex: "purposeName",
    width: 120,
    align: "center",
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("resource.list.cols.currency"),
    dataIndex: "currencyName",
    width: 100,
    align: "center",
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("resource.list.cols.exchangePoints"),
    dataIndex: "exchangePointsShow",
    width: 140,
    align: "right",
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("resource.list.cols.exchangeAfterPoints"),
    dataIndex: "exchangeAfterPointsShow",
    width: 140,
    align: "right",
    ellipsis: true
  }
];

export default {
  name: "ResourceList",
  components: {
    ECTable,
    resourceModal
  },
  data() {
    return {
      cardList: [
        {
          icon: require("../../assets/images/icon-rest.png"),
          tip: "cash"
        },
        {
          icon: require("../../assets/images/icon-back.png"),
          tip: "back"
        },
        {
          icon: require("../../assets/images/icon-welfare.png"),
          tip: "wel"
        },
        {
          icon: require("../../assets/images/icon-survey.png"),
          tip: "survey"
        }
      ],
      billList: [],
      columns,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      isShowModal: false,
      loading: false,
      pending: false,
      dateRange: [moment().startOf("year"), moment().endOf("year")]
    };
  },
  computed: {
    ...mapState({
      balance: state => state.user.balance
    }),
    balanceList() {
      return this.balance
        .filter(it => it.currency !== "rmb")
        .map((b, index) => {
          return {
            icon: this.cardList[index % this.cardList.length].icon,
            tip: this.cardList[index % this.cardList.length].tip,
            ...b
          };
        });
    },
    ...mapGetters("user", ["cashBalance"])
  },
  methods: {
    async onModalConfirm(form) {
      if (this.pending) return;
      this.pending = true;
      const res = await exchangePoints({
        currency: form.currency,
        points: pointsSaveFormatter(form.amount),
        remark: `${this.$t("app.name")}${this.$t("resource.list.button")}`
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.pending = false;
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.operation")
      });
      // 操作成功后 刷新列表
      this.getBillList();
      // 操作成功后 刷新余额
      this.$store.dispatch("user/getUserInfo");
      this.isShowModal = false;
      this.pending = false;
    },
    showModal() {
      // await this.getCurrencyList()
      this.isShowModal = true;
    },
    getBillList() {
      this.loading = true;
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      };
      if (this.dateRange && this.dateRange[0]) {
        params.startTime = moment(this.dateRange[0])
          .startOf("date")
          .format(DateFormatFull);
      }
      if (this.dateRange && this.dateRange[1]) {
        params.endTime = moment(this.dateRange[1])
          .endOf("date")
          .format(DateFormatFull);
      }
      pointsRecords(params).then(res => {
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          this.loading = false;
          return;
        }
        let billList = res.data.records || [];
        // 福币转换为配置名称
        billList.forEach((it) => {
          if (it.currency === "benefits_coin") {
            it.currencyName = this.$store.state.user.platformConf.moneyName;
          }
        });
        this.billList = listPointsShowFormatter(billList, [
          "exchangePoints",
          "exchangeAfterPoints",
          "exchangeBeforePoints"
        ]);
        this.total = res.data.total;
        this.pageNum = res.data.current;
        this.pageSize = res.data.size;
        this.loading = false;
      });
    },
    onTableChange(page) {
      this.pageNum = page.current;
      this.pageSize = page.pageSize;
      this.getBillList();
    },
    onDateRangeChange() {
      this.pageNum = 1;
      this.getBillList();
    }
  },
  mounted() {
    this.getBillList();
  }
};
</script>

<style lang="less" scoped>
.resource-list {
  &_head {
    padding: @ec-gutter 0;
  }
  &_content {
    display: flex;
    gap: 20px;
    .content-card {
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #ccd8f0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
      padding: 50px 0;
      text-align: center;
      &:hover {
        box-shadow: 0px 4px 6px 0px rgba(2, 122, 255, 0.21);
        border: 1px solid @primary-color;
      }
      &_left {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 50px;
        height: 50px;
        margin-right: @ec-gutter * 2;
      }
      &_right {
        line-height: 1;
        .content-flex {
          display: flex;
          align-items: center;
        }
        .content-num {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 4px;
          text-align: left;
        }
        .content-label {
          color: @sub-color;
          font-size: 16px;
          margin-right: 6px;
        }
      }
    }
  }
  &_table {
    margin-top: 40px;
    .margin {
      margin-right: @ec-gutter;
    }
  }
}
</style>
