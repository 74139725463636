<template>
  <a-modal
    :title="$t('resource.list.button')"
    :visible="visible"
    @ok="ok"
    @cancel="cancel"
    :maskClosable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      ref="form"
      :labelCol="formItemLayout.labelCol"
      :wrapperCol="formItemLayout.wrapperCol"
      labelAlign="right"
      :colon="false"
    >
      <a-form-model-item :label="$t('resource.modal.currency')" prop="currency">
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.currency"
          :placeholder="messageFormatter($t('select'), $t('resource.modal.currency'))"
          @change="onCurrencyChange"
        >
          <a-select-option
            :value="i.dictCode"
            v-for="i in currencyList"
            :key="i.dictCode"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('resource.modal.amount')" prop="amount">
        <a-input-number
          v-model="form.amount"
          :placeholder="messageFormatter($t('input'), $t('resource.modal.amount'))"
          :min="0"
          :precision="2"
          style="width: 100%;"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('resource.modal.balance')">
        {{ redeemPoints.need }}
      </a-form-model-item>
      <a-form-model-item :label="$t('resource.modal.surplus')">
        {{ redeemPoints.balance }}
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getDic } from "@/api";
import { FETCH_CODE, REG_NUM } from "@/config";
import { exchangePointsRate } from "@/pages/resource/api";
import BigNumber from "bignumber.js";
import { moneyFormatter } from "@/utils/tools";
import { mapGetters } from "vuex";
import { showMsg } from "@/utils";
import { langMixin } from "@/mixins";
export default {
  name: "resourceModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        surplus: null,
        currency: undefined,
        amount: null
      },
      currencyList: [],
      resBalance: 0,
      currencyRateList: []
    };
  },
  computed: {
    ...mapGetters("user", ["cashBalanceNumber"]),
    // 兑换目标业务币所需余额
    redeemPoints() {
      const result = { need: 0, balance: 0 };
      const targetValue = this.form.amount || 0;
      let rate = 1; // 默认 1：1
      const curr = this.currencyRateList.find(it => {
        return (
          it.toCurrency === this.form.currency &&
          it.fromCurrency === "main_coin"
        );
      });
      // 如果配置了取配置的比例 比例值表示 1余额兑换 1*ratio 的目标业务币
      if (curr) {
        rate = curr.ratio;
      }
      const need = BigNumber(targetValue)
        .div(rate)
        .toNumber();
      result.need = moneyFormatter(need);
      result.balance = moneyFormatter(
        BigNumber(this.cashBalanceNumber).minus(need)
      );
      return result;
    },
    formItemLayout() {
      return this.calcFormItemLayout([6, 10]);
    },
    rules() {
      return {
        currency: [
          {
            required: true,
            message: this.messageFormatter(this.$t("select"), this.$t("resource.modal.currency"))
          }
        ],
        amount: [
          {
            required: true,
            message: this.messageFormatter(this.$t("input"), this.$t("right"), this.$t("resource.modal.amount"))
          }
        ]
      };
    }
  },
  methods: {
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.amount = Number(this.form.amount);
          if (this.form.amount <= 0) {
            showMsg({
              flag: 2,
              msg: this.$t("resource.modal.amountMin"),
            });
            return;
          }
          this.$emit("ok", this.form);
        }
      });
    },
    cancel() {
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    async getCurrencyList() {
      const res = await getDic({ code: "currency" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const currencyList = res.data || [];
      this.currencyList = currencyList.filter(it => {
        if (it.dictCode === "benefits_coin") {
          it.dictName = this.$store.state.user.platformConf.moneyName;
        }
        return ["benefits_coin", "back_tone_coin", "survey_coin"].includes(
          it.dictCode
        );
      });
    },
    async getCurrencyRate() {
      const res = await exchangePointsRate({
        pageNum: 1,
        pageSize: 999,
        companyId: this.$store.state.user.info.currentCompany.companyId
      });
      this.currencyRateList = res.data.records || [];
    },
    onCurrencyChange(currency) {
      this.resBalance =
        this.currencyList.find(i => i.currency === currency)?.points || 0;
    }
  },
  mounted() {
    this.getCurrencyList();
    this.getCurrencyRate();
  }
};
</script>

<style lang="less" scoped></style>
